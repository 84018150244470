@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?wqoo6e') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?wqoo6e') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?wqoo6e##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="trpr__icon-"], [class*=" trpr__icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trpr__icon-sunrise {
  &:before {
    content: $trpr__icon-sunrise; 
  }
}
.trpr__icon-cw {
  &:before {
    content: $trpr__icon-cw; 
  }
}
.trpr__icon-arrow-right {
  &:before {
    content: $trpr__icon-arrow-right; 
  }
}
.trpr__icon-itinerary {
  &:before {
    content: $trpr__icon-itinerary; 
  }
}
.trpr__icon-prev {
  &:before {
    content: $trpr__icon-prev; 
  }
}
.trpr__icon-next {
  &:before {
    content: $trpr__icon-next; 
  }
}
.trpr__icon-checked {
  &:before {
    content: $trpr__icon-checked; 
  }
}
.trpr__icon-unchecked {
  &:before {
    content: $trpr__icon-unchecked; 
  }
}
.trpr__icon-filters {
  &:before {
    content: $trpr__icon-filters; 
  }
}
.trpr__icon-overnight {
  &:before {
    content: $trpr__icon-overnight; 
  }
}
.trpr__icon-forward-alt {
  &:before {
    content: $trpr__icon-forward-alt; 
  }
}
.trpr__icon-road {
  &:before {
    content: $trpr__icon-road; 
  }
}
.trpr__icon-placeholder {
  &:before {
    content: $trpr__icon-placeholder; 
  }
}
.trpr__icon-binoculars {
  &:before {
    content: $trpr__icon-binoculars; 
  }
}
.trpr__icon-camera {
  &:before {
    content: $trpr__icon-camera; 
  }
}
.trpr__icon-web {
  &:before {
    content: $trpr__icon-web; 
  }
}
.trpr__icon-atlas-view {
  &:before {
    content: $trpr__icon-atlas-view; 
  }
}
.trpr__icon-trip-planner {
  &:before {
    content: $trpr__icon-trip-planner; 
  }
}
.trpr__icon-forward {
  &:before {
    content: $trpr__icon-forward; 
  }
}
.trpr__icon-back {
  &:before {
    content: $trpr__icon-back; 
  }
}
.trpr__icon-moonrise {
  &:before {
    content: $trpr__icon-moonrise; 
  }
}
.trpr__icon-re-center {
  &:before {
    content: $trpr__icon-re-center; 
  }
}
.trpr__icon-star {
  &:before {
    content: $trpr__icon-star; 
  }
}
.trpr__icon-phone {
  &:before {
    content: $trpr__icon-phone; 
  }
}
.trpr__icon-search {
  &:before {
    content: $trpr__icon-search; 
  }
}
.trpr__icon-new-tab {
  &:before {
    content: $trpr__icon-new-tab;     
    color: #505050;
  }
}
.trpr__icon-alert {
  &:before {
    content: $trpr__icon-alert; 
  }
}
.trpr__icon-minus {
  &:before {
    content: $trpr__icon-minus; 
  }
}
.trpr__icon-plus {
  &:before {
    content: $trpr__icon-plus; 
  }
}
.trpr__icon-close {
  &:before {
    content: $trpr__icon-close; 
  }
}
.trpr__icon-pdf {
  &:before {
    content: $trpr__icon-pdf; 
  }
}
.trpr__icon-gpx {
  &:before {
    content: $trpr__icon-gpx; 
  }
}
.trpr__icon-email {
  &:before {
    content: $trpr__icon-email; 
  }
}
.trpr__icon-time {
  &:before {
    content: $trpr__icon-time; 
  }
}
.trpr__icon-marker {
  &:before {
    content: $trpr__icon-marker; 
  }
}
.trpr__icon-flag {
  &:before {
    content: $trpr__icon-flag; 
  }
}
.trpr__icon-plane {
  &:before {
    content: $trpr__icon-plane; 
  }
}
.trpr__icon-triangle {
  &:before {
    content: $trpr__icon-triangle; 
  }
}

