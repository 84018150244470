$icomoon-font-family: "trpr-icons" !default;
$icomoon-font-path: "fonts" !default;

$trpr__icon-sunrise: "\e906";
$trpr__icon-cw: "\e925";
$trpr__icon-arrow-right: "\e920";
$trpr__icon-itinerary: "\e924";
$trpr__icon-prev: "\e917";
$trpr__icon-next: "\e919";
$trpr__icon-checked: "\e916";
$trpr__icon-unchecked: "\e918";
$trpr__icon-filters: "\e911";
$trpr__icon-overnight: "\e915";
$trpr__icon-forward-alt: "\e90c";
$trpr__icon-road: "\e912";
$trpr__icon-placeholder: "\e913";
$trpr__icon-binoculars: "\e914";
$trpr__icon-camera: "\e904";
$trpr__icon-web: "\e922";
$trpr__icon-atlas-view: "\e900";
$trpr__icon-trip-planner: "\e901";
$trpr__icon-forward: "\e902";
$trpr__icon-back: "\e903";
$trpr__icon-moonrise: "\e905";
$trpr__icon-re-center: "\e907";
$trpr__icon-star: "\e908";
$trpr__icon-phone: "\e909";
$trpr__icon-search: "\e90a";
$trpr__icon-new-tab: "\e90b";
$trpr__icon-alert: "\e90d";
$trpr__icon-minus: "\e90e";
$trpr__icon-plus: "\e90f";
$trpr__icon-close: "\e910";
$trpr__icon-pdf: "\e91a";
$trpr__icon-gpx: "\e91b";
$trpr__icon-email: "\e91c";
$trpr__icon-time: "\e91e";
$trpr__icon-marker: "\e91f";
$trpr__icon-flag: "\e921";
$trpr__icon-plane: "\e923";
$trpr__icon-triangle: "\e91d";

